@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  min-width: 0;
  min-height: 0;
  flex-shrink: 0;
}
*::selection {
  @apply bg-brand-500/80 text-white;
}

:root {
  @apply text-[16px] md:text-[14px] overscroll-none;
  color-scheme: light dark;
  --tw-border-opacity: 1;
  --tw-bg-opacity: 1;
  --tw-text-opacity: 1;
}

html,
body {
  @apply stack size-full flex-auto overscroll-none antialiased min-h-screen font-sans bg-contrast-0 text-contrast-700;
}

hr {
  @apply border-contrast-100;
}

.warning-stripes {
  background-image: repeating-linear-gradient(
    45deg,
    currentColor 0,
    currentColor 10px,
    transparent 10px,
    transparent 20px
  );
}

@layer components {
  .link-underline {
    @apply underline decoration-2 underline-offset-4 decoration-gray-500/40;
  }
}
